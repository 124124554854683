import * as R from 'ramda'
import {arrayOf, number, shape, string} from 'prop-types'
import React from 'react'

import Description from 'components/UI/Description'
import Media from 'components/UI/Media'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './styles'

const Values = ({title, values, valueIcons}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  const completeArrayOfValues = () => {
    const arr = []

    R.map(value => {
      const obj = {
        name: value.name,
        description: value.description,
        icon: '',
      }

      // eslint-disable-next-line fp/no-mutating-methods
      return arr.push(obj)
    }, values)

    return arr
  }

  const addIcon = array => {
    const arr = []

    mapIndexed((el, index) => {
      const obj = {
        name: el.name,
        description: el.description,
        icon: valueIcons[index],
      }

      // eslint-disable-next-line fp/no-mutating-methods
      return arr.push(obj)
    }, array)

    return arr
  }

  const arrayOfValues = addIcon(completeArrayOfValues())

  return (
    <Section hasPaddingTop={false} hasPaddingBottom={false}>
      <div className={classes.miniPictures}>
        <Title type="title" variant="h2" isCentered>
          {title}
        </Title>
        {values &&
          mapIndexed(
            (value, mapIndex) => (
              <div key={mapIndex} className={classes.values}>
                <div className={classes.imageWrap}>
                  <Media
                    className={classes.subLevelImage}
                    data={value.icon}
                    alt={value.name}
                  />
                </div>
                <div classeName={classes.content}>
                  <h3 className={classes.title}>{value.name}</h3>
                  <Description type="paragraph">
                    {value.description}
                  </Description>
                </div>
              </div>
            ),
            arrayOfValues,
          )}
      </div>
    </Section>
  )
}

Values.propTypes = {
  title: string,
  valueIcons: arrayOf(
    shape({
      fluid: shape({
        aspectRatio: number,
        base64: string,
        sizes: string,
        src: string,
        srcSet: string,
        srcSetWebp: string,
        srcWebp: string,
      }),
    }),
  ),
  values: arrayOf(
    shape({
      name: string,
      description: string,
      icon: shape({
        aspectRatio: number,
        base64: string,
        sizes: string,
        src: string,
        srcSet: string,
        srcSetWebp: string,
        srcWebp: string,
      }),
    }),
  ),
}

Values.defaultProps = {
  title: '',
  valueIcons: arrayOf(
    shape({
      fluid: shape({
        aspectRatio: 1,
        base64: '',
        sizes: '',
        src: '',
        srcSet: '',
        srcSetWebp: '',
        srcWebp: '',
      }),
    }),
  ),
  values: arrayOf(
    shape({
      name: '',
      description: '',
      icon: shape({
        aspectRatio: 0,
        base64: '',
        sizes: '',
        src: '',
        srcSet: '',
        srcSetWebp: '',
        srcWebp: '',
      }),
    }),
  ),
}

export default Values
