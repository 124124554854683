import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  wrapper: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  image: {
    margin: spacing(1),
    borderRadius: spacing(5, 0),
    transform: 'translateZ(0)',
    width: '100%',
    [breakpoints.down('sm')]: {
      margin: `${spacing(1)} auto`,
    },
  },
}))

export default useStyles
