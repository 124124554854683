import * as R from 'ramda'
import {number, shape, string} from 'prop-types'
import React from 'react'

import Media from 'components/UI/Media'

import useStyles from './styles'

const ImageSection = ({imageSection}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  return (
    <div className={classes.wrapper}>
      {mapIndexed(
        (img, index) => (
          <Media key={index} className={classes.image} data={img} />
        ),
        imageSection,
      )}
    </div>
  )
}

ImageSection.propTypes = {
  imageSection: shape({
    fluid: shape({
      aspectRatio: number,
      base64: string,
      src: string,
      srcSet: string,
      srcSetWebp: string,
      srcWebp: string,
    }),
  }),
}

ImageSection.defaultProps = {
  imageSection: shape({
    fluid: shape({
      aspectRatio: 1,
      base64: '',
      src: '',
      srcSet: '',
      srcSetWebp: '',
      srcWebp: '',
    }),
  }),
}

export default ImageSection
