import * as R from 'ramda'
import {arrayOf, bool, objectOf, shape, string} from 'prop-types'
import {graphql} from 'gatsby'
import React from 'react'

import {pageContextPropTypes} from 'helpers/propTypes'
import {slugTransformer} from 'helpers/utils'
import Hero from 'components/UI/Hero'
import ImageSection from 'components/JoinUs/ImageSection'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import Social from 'components/UI/Social'
import Title from 'components/UI/Title'
import Values from 'components/JoinUs/Values'

const JoinUsPage = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)

  const {
    title,
    surtitle,
    metaTitle,
    metaDescription,
    socialLinks,
    callToAction,
    image,
    imageSection,
    firstSection,
    secondSection,
    thirdSection,
    fourthSection,
    callToActionLinkedPage,
    values,
    valueIcons,
    firstSectionTitle,
    secondSectionTitle,
    thirdSectionTitle,
    fourthSectionTitle,
  } = R.pathOr(' ', ['contentfulJoinUs'], data)

  const {shortDescription} = R.pathOr(
    ' ',
    ['contentfulJoinUs', 'shortDescription'],
    data,
  )

  const {values: arrayOfValues} = values

  const klsSlug = slugTransformer(callToActionLinkedPage.slug)

  const richTextDocuments = R.pathOr(null, ['richTextDocuments', 'nodes'], data)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription:
      R.pathOr('', ['metaDescription'], metaDescription) || metaDescription,
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <Hero
          hat={surtitle}
          title={title}
          titleType="title"
          description={shortDescription}
          media={image}
          ctaColor="primary"
          descriptionType="largeDescription"
        />
        <RoundButton arrow color="secondary" isCentered href={klsSlug}>
          {callToAction}
        </RoundButton>
        <Social
          socialMediaLinks={socialLinks}
          isLarge
          hasMarginTop
          isCentered
          hasPrimaryColor
          hasBorderRadius
          isSpaced
        />
      </Section>
      <Section hasSmallPadding hasSmallWidth>
        <Title type="title" variant="h2" isCentered>
          {firstSectionTitle}
        </Title>
        <RawText text={firstSection} richTextDocuments={richTextDocuments} />
      </Section>
      <Section hasSmallPadding hasSmallWidth hasPaddingBottom={false}>
        <Title type="title" variant="h2" isCentered>
          {secondSectionTitle}
        </Title>
        <RawText text={secondSection} richTextDocuments={richTextDocuments} />
      </Section>
      <Section hasPaddingTop={false}>
        <ImageSection imageSection={imageSection} />
      </Section>
      <Section hasSmallPadding hasSmallWidth>
        <Title type="title" variant="h2" isCentered>
          {thirdSectionTitle}
        </Title>
        <RawText text={thirdSection} richTextDocuments={richTextDocuments} />
      </Section>
      <Values
        title={values.title}
        values={arrayOfValues}
        valueIcons={valueIcons}
      />
      <Section hasGreyBackground hasSmallPadding hasSmallWidth>
        <Title type="title" variant="h2" isCentered>
          {fourthSectionTitle}
        </Title>
        <RawText text={fourthSection} richTextDocuments={richTextDocuments} />
      </Section>
    </>
  )
}

JoinUsPage.propTypes = {
  data: shape({
    contentfulJoinUs: shape({
      callToAction: string,
      callToActionLinkedPage: shape({slug: string}),
      firstSection: {
        raw: string,
      },
      fourthSection: {
        raw: string,
      },
      image: shape({
        fluid: {},
      }),
      imageSection: shape(
        arrayOf(
          objectOf(
            shape({
              fluid: {},
            }),
          ),
        ),
      ),
      metaDescription: string,
      metaTitle: string,
      secondSection: {
        raw: string,
      },
      shortDescription: shape({
        shortDescription: string,
      }),
      slug: string,
      description: string,
      socialLinks: shape(
        arrayOf(
          objectOf(
            shape({
              icon: {},
              iconHover: {},
              id: string,
              link: string,
              name: string,
            }),
          ),
        ),
      ),
      surTitle: string,
      thirdSection: {
        raw: string,
      },
      valueIcons: shape(
        arrayOf(
          objectOf(
            shape({
              fluid: {},
            }),
          ),
        ),
      ),
      values: shape({
        title: string,
        values: arrayOf(
          objectOf(
            shape({
              description: string,
              name: string,
            }),
          ),
        ),
      }),
    }),
    contentfulCountry: shape({
      countryCode: string,
      navLocale: string,
      urlPrefix: string,
      isMultilang: bool,
      technicalName: string,
    }),
  }),
  pageContext: shape({
    lastMod: string,
    nodeLocale: string,
    prefix: string,
    slug: string,
    technicalName: string,
  }),
}

JoinUsPage.defaultProps = {
  data: shape({
    contentfulJoinUs: {},
    contentfulCountry: {},
  }),
  pageContext: pageContextPropTypes.isRequired,
}

export default JoinUsPage

export const pageQuery = graphql`
  query templateJoinUsPage(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulJoinUs(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      image {
        gatsbyImageData(placeholder: BLURRED)
      }
      title
      slug
      surtitle
      metaTitle
      metaDescription
      shortDescription {
        shortDescription
      }
      callToAction
      callToActionLinkedPage {
        slug
      }
      socialLinks {
        id
        name
        link
        icon {
          file {
            url
          }
        }
        iconHover {
          file {
            url
          }
        }
      }
      firstSectionTitle
      secondSectionTitle
      thirdSectionTitle
      fourthSectionTitle
      firstSection {
        raw
      }
      secondSection {
        raw
      }
      thirdSection {
        raw
      }
      fourthSection {
        raw
      }
      imageSection {
        gatsbyImageData(placeholder: BLURRED)
      }
      values {
        title
        values {
          name
          description
        }
      }
      valueIcons {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    richTextDocuments: allContentfulDocument(
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        contentful_id
        name
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
  }
`
